import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { H3 } from "./Text";

interface FAQItemProps {
  question: string;
  answer: string;
}

import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-700 py-4 w-full">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-gray-200">{question}</span>
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 text-gray-400 flex-shrink-0" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-gray-400 flex-shrink-0" />
        )}
      </button>
      {isOpen && <p className="mt-2 text-gray-400 w-full">{answer}</p>}
    </div>
  );
};

export const FAQ: React.FC = () => {
  const faqItems = [
    {
      question: "Why does Next Level Jobs EU cost money?",
      answer:
        "The board crawls thousands of job listings every day to find great opportunities. This costs money to operate and maintain the service at a high quality.",
    },
    {
      question: "Can I cancel my subscription?",
      answer:
        "Yes, you can cancel your subscription towards the end of your billing cycle (1 month, 3 months, or 1 year).",
    },
    {
      question: "How do you decide which jobs to add to the board?",
      answer:
        "We only parse companies which are able to pay 100k euros per year for senior engineers according to sources like levels.fyi. For certain countries or lower-level roles, these companies might pay slightly less.",
    },
    {
      question: "How is Next Level Jobs EU different from other job boards?",
      answer:
        "Unlike traditional job boards, companies can't post directly on our site. This keeps our incentives aligned with job seekers, as we independently curate high-quality listings. This approach ensures that only the most relevant and promising opportunities make it to our board.",
    },
  ];

  return (
    <div className="max-w-3xl w-full mx-auto mt-12">
      <H3 className="text-2xl font-bold text-gray-100 mb-6">
        Frequently Asked Questions
      </H3>
      {faqItems.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};
